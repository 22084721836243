<template>
  <v-row align="center" justify="center">
    <v-col> Dashboard</v-col>
  </v-row>
</template>

<script>
export default {
  name: "Dashboard",
  components: {},
};
</script>
